import { Fragment, useEffect, useState } from "react";

import {
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon,
  Collapse,
  Box,
  Divider,
  ListSubheader,
  Badge,
} from "@mui/material";
import Dashboard from "@mui/icons-material/Dashboard";
import Payment from "@mui/icons-material/Payment";
import LineStyle from "@mui/icons-material/LineStyle";
import TrendingUp from "@mui/icons-material/TrendingUp";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Home from "@mui/icons-material/Home";
import ListAlt from "@mui/icons-material/ListAlt";
import Shop from "@mui/icons-material/Shop";

import { hashHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import menuItems from "../../utils/app/menuItems";
import styles from "./styles";
import CreateReport from "containers/Home/CreateReport/CreateReport";
import { NewReport } from "../NewReport/NewReport";
import { updateReportConfig } from "redux/ReportsConfig/actions";
import { updateAppBar } from "redux/AppBar/actions";
import { activateItemMenu, isAvailableForPlan } from "utils/utils";
import { updateAppState } from "redux/App/actions";
import moment from "moment";
import {
  PLAN_MANAGEMENT,
  TRIALS,
  TRIAL_RESTART_PERIOD,
  UPGRADE,
} from "utils/app/appConstants";
import { useIntegrationData } from "containers/Marketplaces/useIntegrationData";

const menuIcons = {
  home: <Home sx={styles.menuIconStyle} />,
  sales: <TrendingUp sx={styles.menuIconStyle} />,
  publishing: <Dashboard sx={styles.menuIconStyle} />,
  keywords: <LineStyle sx={styles.menuIconStyle} />,
  reports: <ListAlt sx={styles.menuIconStyle} />,
  profitability: <Payment sx={styles.menuIconStyle} />,
  sources: <Shop sx={styles.menuIconStyle} />,
};

const { ACTIVATE_TRIAL, UPGRADE_PLAN } = PLAN_MANAGEMENT;

export function AppMenuMobil({ onItemClick }) {
  const [openDialog, setOpenDialog] = useState(false);
  //const { sourceError } = useMenuData();
  const { sourceError } = useIntegrationData();
  const appBar = useSelector((state) => state.get("appBar"));
  const [menuList, setMenuList] = useState({ ...appBar });
  const route = useSelector((state) => state.get("route"));
  const app = useSelector((state) => state.get("app"));
  const [currentPlan, setPlan] = useState("free");
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const dispatch = useDispatch();

  const { accountData } = userIdentity;

  const { trial, plan } = accountData?.properties || {};
  const { type, start_date, duration } =
    (trial && Array.isArray(trial) ? trial[0] : trial) || {};

  const { blockedRoute } = app;

  const routePath = route
    .get("locationBeforeTransitions")
    .pathname.split("/")
    .filter((path) => path !== "");

  useEffect(() => {
    if (trial) {
      let now = moment(new Date());
      let trialStart = moment(start_date, "YYYY-MM-DD”");
      let days = now.diff(trialStart, "days");
      const remainingDays = duration - days;

      if (remainingDays > 0) setPlan(type.toLowerCase());
      else setPlan(plan?.toLowerCase() || "free");
    } else setPlan(plan?.toLowerCase() || "free");
  }, [trial, plan]);

  useEffect(() => {
    const newMenu = { ...menuItems };
    Object.entries(newMenu).forEach(([key, value]) => {
      if (value.subItems) {
        newMenu[key] = {
          ...value,
          open: false,
        };
      }
    });
    setMenuList(newMenu);
    let itemKey = routePath[0];
    let subItemId = routePath[1];
    if (
      subItemId === "dashboard" &&
      itemKey !== "keywords" &&
      itemKey !== "publishing"
    ) {
      itemKey = routePath[1];
      subItemId = routePath[0];
    }
    //const subItemId = itemKey === "marketplaces" ? routePath[1] : routePath[2];
    dispatch(updateAppBar(activateItemMenu(appBar, itemKey, subItemId)));
  }, [route]);

  useEffect(() => {
    setMenuList({ ...appBar });
  }, [appBar]);

  const handleClick = async (event, menuKey) => {
    if (isAvailableForPlan(currentPlan, menuList[menuKey].plan)) {
      if (menuList[menuKey].subItems) {
        const newState = { ...menuList };
        Object.entries(newState).forEach(([key, value]) => {
          if (key === menuKey) {
            newState[key].open = !newState[key].open;
          } else newState[key].open = false;
        });
        setMenuList(newState);
      } else {
        onItemClick();
        goTo(menuList[menuKey].rootPath, menuKey);
      }
    } else {
      let trialAvailable = currentPlan === "free" && !trial;
      if (!trialAvailable && currentPlan === "free") {
        let now = moment(new Date());
        let trialStart = moment(start_date, "YYYY-MM-DD”");
        let days = now.diff(trialStart, "days");
        if (days > TRIAL_RESTART_PERIOD) trialAvailable = true;
      }
      const { title, subtitle, features, cta } = trialAvailable
        ? TRIALS.PRO
        : UPGRADE.PRO;
      const planManagement = {
        action: trialAvailable ? ACTIVATE_TRIAL : UPGRADE_PLAN,
        open: true,
        title,
        subtitle,
        features,
        cta,
      };
      dispatch(updateAppState({ planManagement }));
    }
  };

  const handleOnSubItemClick = (path, key, subItemId, route, plan) => {
    onItemClick();
    if (isAvailableForPlan(currentPlan, plan)) {
      return goTo(path, key, subItemId);
    } else {
      let trialAvailable = currentPlan === "free" && !trial;
      if (!trialAvailable && currentPlan === "free") {
        let now = moment(new Date());
        let trialStart = moment(start_date, "YYYY-MM-DD”");
        let days = now.diff(trialStart, "days");
        if (days > TRIAL_RESTART_PERIOD) trialAvailable = true;
      }
      const { title, subtitle, features, cta } = trialAvailable
        ? TRIALS.PRO
        : UPGRADE.PRO;
      const planManagement = {
        action: trialAvailable ? ACTIVATE_TRIAL : UPGRADE_PLAN,
        open: true,
        title,
        subtitle,
        features,
        cta,
      };
      dispatch(updateAppState({ planManagement }));
    }
  };

  const goTo = (path, key, subItemId) => {
    if (window.location.hash.includes(blockedRoute)) {
      dispatch(updateAppState({ showRouteBlockedAlert: true, goNext: path }));
      return;
    }

    let title = `${menuList[key].name}`;
    const host = window.location.hostname.split(".");
    window.document.title = `${title} | ${host[1]}`;
    dispatch(
      updateReportConfig({
        filters: [],
        quickFilters: {},
        lastFilterSelected: [],
        dashboardMetric: "sales",
        loadingConfig: true,
        offset: 0,
        isSearch: false,
        searchCriteria: undefined,
        reportType: undefined,
        filtersLabels: {},
        filtersKeys:{}
      })
    );
    dispatch(updateAppBar(activateItemMenu(appBar, key, subItemId)));
    hashHistory.push(path);
  };

  return (
    <>
      {Object.entries(menuList).map(([key, menu]) => (
        <Fragment key={key}>
          <ListItemButton
            onClick={(e) => handleClick(e, key)}
            sx={{ background: menu.active ? "#E2EBFD" : "transparent" }}
          >
            <ListItemIcon>{menuIcons[key]}</ListItemIcon>
            <ListItemText
              primary={
                <span className="body1" style={{ fontSize: "0.87em" }}>
                  {menu.name}
                </span>
              }
              data-intercom-target={`menu-${key}`}
              id={`menu-${key}`}
            />
            <Box sx={{ display: menu.subItems ? "bloc" : "none" }}>
              {menu.open ? (
                <ExpandLess sx={styles.menuIconStyle} />
              ) : (
                <ExpandMore sx={styles.menuIconStyle} />
              )}
            </Box>
            <Badge
              badgeContent={sourceError}
              invisible={key !== "sources"}
              sx={{
                "& .MuiBadge-badge": {
                  background: "#F22C36",
                  mr: 1,
                  color: "#FFF",
                },
              }}
            />
           {/*  {key === "publications" && (
              <div style={{ marginLeft: "0.5em" }}>
                <NewTag tag="beta" />
              </div>
            )} */}
            {menu.plan !== "free" && (
              <img
                style={{ height: "0.8em", opacity: 0.4 }}
                src="/images/premium-quality.svg"
                alt=""
              />
            )}
          </ListItemButton>
          <Collapse in={menu.open} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={styles.colapse}
              subheader={
                key === "reports" && (
                  <ListSubheader component="div" id="nested-list-subheader">
                    <NewReport
                      handleOnClick={(e) => {
                        onItemClick(e);
                        setOpenDialog(true);
                      }}
                    />
                    <Divider variant="middle" component="li" />
                  </ListSubheader>
                )
              }
            >
              {menu.subItems &&
                menu.subItems.map((item) => (
                  <Fragment key={item.id}>
                    <ListItemButton
                      sx={
                        item.active
                          ? { ...styles.menuItemActive, pl: 4 }
                          : { pl: 4 }
                      }
                      onClick={() =>
                        handleOnSubItemClick(
                          item.route,
                          key,
                          item.id,
                          null,
                          item.plan
                        )
                      }
                    >
                      <ListItemText
                        primary={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="body2"
                              style={{
                                color: item.active ? "#3770EF" : "#78909C",
                              }}
                            >
                              {item.name}
                            </span>
                            <Badge
                              badgeContent={sourceError}
                              invisible={item.id !== "accounts"}
                              sx={{
                                "& .MuiBadge-badge": {
                                  background: "#F22C36",
                                  color: "#FFF",
                                  //mr: 1,
                                },
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "0.5em",
                              }}
                            >
                              {item.plan !== "free" && (
                                <img
                                  style={{ height: "0.8em", opacity: 0.4 }}
                                  src="/images/premium-quality.svg"
                                  alt=""
                                />
                              )}
                              {/* {item.id === "profitability" && (
                                <NewTag tag="beta" />
                              )} */}
                            </div>
                          </div>
                        }
                      />
                    </ListItemButton>
                    <Divider variant="middle" component="li" sx={{ ml: 4 }} />
                  </Fragment>
                ))}
            </List>
          </Collapse>
        </Fragment>
      ))}
      <CreateReport
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
}
