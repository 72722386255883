import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import WivoButton from "components/WivoButton/Button";
import { localization } from "localization/es";
import styles from "./styles";
import Close from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import parse from "html-react-parser";

const Modal = (props) => {
  const {
    fullScreen,
    fullWidth,
    open,
    handleClose,
    title,
    message = "",
    type,
    notification,
    content,
    label,
    cancelLabel,
    toClose,
    maxWidth = "sm",
    minHeight,
    disabledOkButton,
    prevCloseOnOK,
    ok_button_data_cy,
  } = props;
  const {
    BUTTONS: { CANCEL, ACCEPT },
  } = localization;

  return (
    <Dialog
      open={open}
      //fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minHeight,
        },
      }}
      classes={{
        paper: {
          background: "#FFFFFF",
          borderRadius: 40,
          padding: "10px",
          minWidth: "30%",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
        <div style={styles.titleSection}>
          <span className="body1" style={{ fontWeight: 500 }}>
            {title}
          </span>
          {toClose && (
            <Close
              fontSize="small"
              onClick={(e, reason) => {
                handleClose(
                  { ...e, target: { ...e.target, name: "closeAction" } },
                  reason
                );
              }}
              style={styles.closeIcon}
            />
          )}
        </div>
      </DialogTitle>
      <DialogContent data-intercom-target="dialog-content" id="dialog-content">
        {message && (
          <p className="body2" style={{ display: "inline" }}>
            {typeof message === "string" ? parse(message) : message}
          </p>
        )}
        {content && (
          <div
            style={{
              maxHeight: !fullScreen ? "150px" : "100%",
              overflow: !fullScreen ? "auto" : "unset",
              margin: "0.5rem 0",
            }}
          >
            {content}
          </div>
        )}
      </DialogContent>
      <DialogActions sx={{ margin: "0.5em" }}>
        <Stack
          sx={{ w: "100%" }}
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1 }}
          justifyContent={{ xs: "center", sm: "flex-end" }}
        >
          {!notification && (
            <WivoButton
              data_intercom_target={cancelLabel || CANCEL}
              color="default"
              label={cancelLabel || CANCEL}
              handleOnClick={(e, reason) => {
                handleClose(
                  { ...e, target: { ...e.target, name: "cancelAction" } },
                  reason
                );
              }}
              outlined
            />
          )}
          <div style={{ width: 10 }} />
          {type === "confirm" && (
            <WivoButton
              data_cy={ok_button_data_cy}
              minWidth={"100px"}
              disabled={disabledOkButton}
              data_intercom_target={label || ACCEPT}
              label={label || ACCEPT}
              handleOnClick={(e, reason) => {
                !prevCloseOnOK &&
                  handleClose(
                    { ...e, target: { ...e.target, name: "okAction" } },
                    reason
                  );
                props.okAction();
              }}
            />
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
