export const localization = {
  APP: {
    LOAD_TITLE: "Cargando título...",
    SUPPORT: "Contactar con soporte",
    ADD_REPORT: "Crear reporte",
    INVITE: "Invitar",
    CHAT: "Chatear",
    LOADING_MESSAGE: " Por favor espere",
    APPLY: "Aplicar",
    ACTION_ERROR: "Oops!, Algo ha ido mal",
    ERROR: "Oops!, Algo ha ido mal, por favor contacte el equipo de soporte.",
    ERROR_MESSAGE:
      "Ocurrió un error durante la operación, intente nuevamente más tarde.",
    TRUST_US: "200+ vendedores confían en nosotros",
    NEW_VERSION_AVAILABLE:
      "Se ha detectado una nueva versión de la aplicación. Por favor recargue la página para empezar a usarla.",
    PAGE_RELOAD: "RECARGAR",
    AUTOMATICALLY_RELOAD_PAGE: "La página se recargará automáticamente ",
    WELCOME_DIALOG_TITLE: "Estamos cargando tus datos",
    WELCOME_MESSAGE:
      "Ya estamos sincronizando los datos de %sourceName% con nuestros servidores. En unos minutos podrás verlos en la aplicación.",
    WELCOME_AMRKETPLACE_PROMO:
      "Por mientras puedes integrar más marketplaces a tu cuenta gratuita.",
    SYNCHRONIZATION_FINISHED_TITLE: "Datos cargados",
    SYNCHRONIZATION_FINISHED:
      "La sincronización ha finalizado, para ver tus datos en la aplicación recargue la página.",
    LIMITED_ACTION: "Esta funcionalidad está restringida para el demo.",
    SINGUP_REQUEST:
      " Para ingresar al demo necesitas crear primero una cuenta en Wivo Analytics.",
    SINGUP_SUBSCRIPTION:
      "Quiero recibir promociones e información en mi correo",
    SCREEN_WARNING:
      "  Te recomendamos usar el demo en en Escritorio para que puedas acceder a todas las funcionalidades.",
    UPGRADE_TITLE: "Descubre más con un plan superior",
    UPGRADE_DESCRIPTION:
      "Mejora a un plan superior para desbloquear esta funcionalidad.",
  },
  REPORTS: {
    NEW_REPORT_TYPE: "Selecciona el tipo de reporte que deseas crear",
    DUPLICATED_TITLE: "Reporte duplicado",
    DUPLICATED: "El reporte ha sido duplicado satisfactoriamente.",
    DUPLICATED_ERROR: "Error al intentar duplicar el reporte.",
    SAVED_TITLE: "Reporte guardado",
    SAVED: "El reporte ha sido guardado satisfactoriamente.",
    SAVED_ERROR: "Error al intentar guardar el reporte.",
    DELETE_TITLE: "Reporte eliminado",
    DELETE: "El reporte ha sido elimnado satisfactoriamente.",
    DELETE_ERROR: "Error al intentar eliminar el reporte.",
    DELETED_LIMITED: "El reporte no se puede eliminar",
    DELETE_REPORT: "Eliminar reporte",
    DELETE_CONFIRM: "¿Estás seguro que desea eliminar el reporte?",
  },
  BANNER: {
    PROMO: "¿Quieres ver tus datos en los reportes?",
    LINK: "Crea una cuenta GRATIS",
  },
  SEARCH: {
    DEFAULT_PLACEHOLDER: "Buscar...",
  },

  CALENDAR: {
    PURCHASE_DATE_LABEL: "Fecha de compra",
    PAYMENT_DATE_LABEL: "Fecha de pago disponible",
  },
  BUTTONS: {
    ADD: "Agregar",
    FILTER: "Filtros",
    COLUMNS: "Columnas",
    GRAPH: "Gráfico",
    NEXT: "Siguiente",
    BACK: "Atrás",
    ACCEPT: "Aceptar",
    CANCEL: "Cancelar",
    EXIT: "Salir",
    GO_DEMO: "Ir a demo",
    BTN_CONNECT: "Conectar",
    GO_MARKTPLACE: "Ir a integraciones",
    DUPLICATE: "Duplicar",
    SAVE: "Guardar",
    DOWNLOAD: "Descargar",
    DISCARD: "Descartar",
    DELETE: "Eliminar",
    GO_HOME: "Ir a inicio",
    INTEGRATE: "Integrar otro marketplace",
  },
  TOPMENU: {
    HOME: "Inicio",
    SALES: "Dashboard",
    DASHBOARD: "Dashboards",
    REPORTS: "Reportes",
    ORDERS: "Órdenes",
    FINANCES: "Finanzas",
    FINANCESITEMS: {},
    MARKETPLACE: "Marketplaces",
    DATAITEMS: {
      MASTER: "Maestro de productos",
      SOURCES: "Fuentes",
    },
    PUBLISHING: "Publicaciones",
    COMPETITION: "Categorías",
    PROFITABILITY: "Rentabilidad",
    PUBLICATIONS_MONITOR: "Publicaciones",
  },
  LOGIN: {
    LOGOUT: "Salir",
  },
  SINGUP: {
    TITLE: "¡Comencemos!",
    NAME_LABEL: "Nombre",
    LAST_NAME_LABEL: "Apellido",
    COMPANY_NAME: "Empresa",
    PASSWORD_LABEL: "Contraseña",
    PASSWORD_PLACEHOLDER: "Ingrese al menos 6 caracteres",
    EMAIL_LABEL: "Correo corporativo",
    GENERIC_ERROR: "% es un campo obligatorio",
    INVALID_EMAIL: "Email inválido",
    INVALID_USERNAME: "Usuario inválido",
    INVALID_URL: "URL inválido",
    PASSWORD_LEN: "El password debe contender al menos 6 caracteres",
    REGISTER_BUTTON: "Registrarme",
    CANCEL_BUTTON: "Cerrar",
    TITLE_HELP_MODAL: "Ya existe un registro de esta cuenta",
    DESC_HELP_MODAL:
      "Escoge reenviar el correo con el acceso a tu cuenta si quieres acceder o comunicate con nuestro equipo de soporte para ayudarte.",
    DESC_HELP_WITHOUT_RESEND:
      "Comunícate con nuestro equipo de soporte si necesitas ayuda",
    HELP_BUTTON: "Ayuda",
    RESEND_BUTON: "Reenviar",
  },
  PAGINATION: {
    ROWS_BY_PAGE: "Filas por página",
    ELEMENTS_SHOW: "a",
    PAGE: "Página",
    PAGE_LENGTH: "de",
  },
  PROGRESS: {
    MESSAGE: "Cargando información. Por favor espere...",
  },
  NOT_FOUND_PAGE: {
    HEADER_MESSAGE: "El componente no fue encontrado",
    NOCONF_MESSAGE: "No existe la configuración de entrada main-config",
    NOMETRIC_MESSAGE: "No se puede acceder al servicio de metricas",
    METRIC_LENGTH_MESSAGE: "No hay metricas para este cliente",
    NO_VIEW_MESSAGE: "No existe la configuración para la página: ",
  },
  SOURCES_MANAGEMENT: {
    APPOITMENT: "Agendar reunión",
    VALIDATING_SOURCE: "Validando credenciales",
    SOURCE_TITLE: "Selecciona el marketplace que quieras integrar",
    SOURCE_SUBTITLE:
      "El plan gratuito admite 2 marketplaces. Siempre puedes agregar otros marketplaces o quitar el acceso en la aplicación",
    SOURCE_VALIDATION_ERROR:
      "Las credenciales de $LAFUENTE son incorrectas. Por favor intenta con otro canal o prueba de nuevo",
    SOURCE_VALIDATION_FAIL:
      "No pudimos validar las credenciales de $LAFUENTE. Por favor intenta con otro canal",
    DELETE_SOURCE_TITLE: "Eliminar fuente",
    NEW_INTEGRATION: "Nuevo Marketplace",
    ACTIVE_SOURCES: "Activas",
    AVAILABLE_SOURCES: "Disponibles",
    SUBTITLE_AVAILABLE_SOURCES:
      "Integra a Wivo todos los canales o servicios que utilices.",
    SOURCE_LOADING: "Cargando las fuentes...",
    DELETE_SOURCE_MESSAGE:
      "¿Estás seguro que deseas eliminar esta fuente? Todos los datos relacionados serán eliminados también. Algunos de estos datos no podrán ser recuperados.",
    CREDENTIAL_MANAGEMENT: {
      BAD_CREDENTIALS: "Credenciales Incorrectas",
      UPDATE_TOKEN: "Autenticación requerida",
      ERROR: "Error",
      WITH_OUT_CREDENTIALS: "Sin Integración",
      OK: "OK",
      HISTORICAL_LOADING: "Carga Histórica",
    },
    HISTORICAL_LOADING: "HISTORICAL_LOADING",
    NEW: "NEW",
    REGULAR: "REGULAR",
    OK: "OK",
    ADD_MARKETPLACE: "Nuevo marketplace",
    SELECT_MARKETPLACE: "Selecciona la cuenta que deseas agregar",
  },
  INTEGRATION: {
    TITLE: "Integración",
    DESC: "Para continuar, necesitamos que integres al menos uno de los marketplaces con que trabajas:",
    SAVE_BUTTON: "Activar",
    PROMO_TITLE: "Prueba GRATIS por 7 días",
    PROMO_ITEMS: [
      "Conecta los datos de todos tus marketplaces.",
      "No gastes tiempo en realizar reportes.",
      "Toma decisiones fácilmente y haz volar tu negocio.",
    ],
    PROMO_TITLE_CLIENT: "Únete a las empresas más innovadoras del ecommerce",
    PROMO_ITEMS_CLIENT: [
      "Conecta los datos de todos tus marketplaces.",
      "No gastes tiempo en realizar reportes.",
      "Toma decisiones fácilmente y haz volar tu negocio.",
    ],
    MARKETPLACE: {
      DESC_LINK: {
        MERCADOLIBRE: "Cómo revisar los permisos de una cuenta ML",
        LINIO: "Cómo obtener el API KEY.",
        RIPLEY: "Cómo obtener el API KEY.",
        FALABELLA: "",
        NEWFALABELLA: "Cómo obtener el API KEY.",
        PARIS: "",
        MAGENTO: "Cómo verificar los permisos de una cuenta Magento",
        DAFITI: "Cómo obtener el API KEY",
        SODIMAC: "",
        VTEX: "Cómo obtener el access key y secret",
        PRESTASHOP: "Cómo obtener el API KEY",
        SHOPIFY: "Cómo integrar tu cuenta Shopify con API token",
        WOOCOMMERCE: "Cómo obtener el CONSUMER KEY y CONSUMER SECRET.",
      },
      FIELD_API: "API KEY",
      FIELD_TOKEN: "API token",
      EMAIL: "Email",
      USERNAME: "Nombre de usuario",
      PASSWORD: "Password",
      VTEX_ACCOUNT: "Cuenta VTEX",
      SHOPOYFY_ACCOUNT: "Nombre de Tienda Shopify",
      SHOP_ID: "Id de la cuenta %account%",
      SHOP_ID_FULFILMENT: "Trabajo con fulfillment en ",
      WOOCOMMERCE_CONSUMER_KEY: "Clave del cliente",
      WOOCOMMERCE_CONSUMER_SECRET: "Clave secreta",
      WALMART_CLIENT_SECRET: "Client Secret",
      WALMART_CLIENT_KEY: "Client ID",
      ALIAS: "Nombre de la cuenta",
      HELP_LINK: "¡Cuéntanos!",
      SAVE_BUTTON: "Guardar",
      AUTH_BUTTON: "Autenticar",
      PLACEHOLDER_EMAIL: "Email asociado a %source%",
      PLACEHOLDER_USERNAME:
        "El nombre de usuario con el cual se inicia sesión en tu tienda",
      PLACEHOLDER_API: "Al menos 16 caracteres",
      PLACEHOLDER_TOKEN: "Introduzca el API token correspondiente",
      PLACEHOLDER_SHOPYFY_ACCOUNT:
        "Introduce el nombre de tu tienda. Por ejemplo, si el url de tu cuenta Shopify es https://perro-azul.myshopify.com el nombre de la tienda sería perro-azul",
      PLACEHOLDER_SHOP_ID: `Sigue las instrucciones del artículo "Cómo obtener el API KEY" para obtener el ID de de cada tienda.`,
      PLACEHOLDER_PASSWORD:
        "Indique el password asociado a su cuenta de marketplace",
      PLACEHOLDER_URL:
        "Indica el URL de tu tienda online, por ejemplo: https://www.mitienda.com",
      PLACEHOLDER_VTEX_ACCOUNT:
        "Por ejemplo micuenta es la cuenta VTEX de https://micuenta.myvtex.com/",
      PLACEHOLDER_WOOCOMMERCE_CONSUMER_KEY: "Introdusca su clave",
      PLACEHOLDER_WOOCOMMERCE_CONSUMER_SECRET: "Introdusca su clave secreta",
      PLACEHOLDER_WALMART_CLIENT_SECRET:
        "Ingresa la llave Client Secret que has generado",
      PLACEHOLDER_WALMART_CLIENT_KEY:
        "Ingresa la llave Client ID que has generado",
      PLACEHOLDER_ALIAS: `Este nombre será útil para diferenciar entre distintas cuentas de un mismo marketplace, ejemplo: "Cuenta Principal ML", "Wivo SpA Ripley", etc.`,
      HELP: "¿Tienes problemas?",
      ERROR: "El api key debe ser de al menos 16 caracteres",
      ERROR_TOKEN: "El token no debe estar vacío",
      ERROR_TOKEN_LENGTH: "El API Token debe ser de al menos 38 caracteres",
      ERROR_USERNAME: "El usuario es un campo obligatorio",
      ERROR_VTEX_ACCOUNT: "la cuenta VTEX es un campo obligatorio",
      ERROR_WOOCOMMERCE: "El campo debe tener como mínimo 20 caracteres",
      ERROR_EMPTY: "Este campo es obligatorio",
      ERROR_SHOP_ID: "Este campo debe ser un número",
      URL: "URL",
      DESC: {
        MERCADOLIBRE: `Cierra tu sesión en Mercado Libre antes de continuar para asegurarte de integrar la cuenta que deseas, y no otra.`,
        LINIO: "Ingresa los datos de tu cuenta en",
        RIPLEY: "Ingresa la clave de API de tu cuenta en",
        FALABELLA: "Ingresa los datos de tu cuenta en",
        NEWFALABELLA: "Ingresa los datos de tu cuenta en",
        PARIS: "Ingresa los datos de tu cuenta en",
        MAGENTO:
          "ingresa el nombre de usuario, password y URL de tu tienda MAGENTO para otorgar los permisos de acceso.",
        DAFITI: "	Ingresa los datos de tu cuenta en",
        SODIMAC: "Ingresa los datos de tu cuenta en",
        VTEX: "Ingresa el access key, secret y nombre de tu cuenta",
        PRESTASHOP: "Ingresa el webservice account key y URL de tu tienda",
        SHOPIFY: `Ingresa el TOKEN y nombre de tu tienda Shopify`,
        WOOCOMMERCE:
          "Ingresa el consumer key, consumer secret y URL de tu tienda",
        WALMART: "Ingresa las claves de API de tu cuenta en Walmart",
        AMAZON: "Conecta la aplicación de Wivo Analytics en Amazon",
        LIVERPOOL: "Ingresa la clave de API de tu cuenta en",
      },
      BACK_BUTTON: "< Volver",
    },
    MESSAGE_ALREADY_ACCOUNT: {
      MCL: "Ya ha registrado la integración para mercado libre",
      MARKETPLACE: "Ya ha registrado credenciales para %source%",
    },
    CONFIRM_ACTIVES: "<b>Fuentes activadas</b>: <br /> %list%",
    CONFIRM_FAILED: "<b>Fuentes fallidas</b>: <br /> %list%",
    OK_BUTTON: "Si",
    CANCEL_BUTTON: "No",
    ALERT_TITLE: "Notificación",
    CONFIRM_TITLE: "Activar Prueba",
    CONFRIM_ACCOUNT_TITLE: "Activar cuenta",
    ALERT_BUTTON: "Ok",
    INVALID_USER_TITLE: "No encontrado",
    INVALID_DESCRIPTION: "Este link ha expirado y no se encuentra disponible.",
  },
  ERRORS: {
    MCL_INTEGRATION_FAIL:
      "Ha ocurrido un error en la integración de mercado libre",
    INITIAL_DATA_FAIL:
      "No se encontraron credenciales para %source%, por favor contacte al equpo wivo",
    MARKETPLACE_INTEGRATION_FAIL:
      "Ha ocurrido un error guardando sus credenciales. Intente más tarde o contacte a soporte@wivo.cl",
    MARKETPLACE_LOAD_FAIL: "Ha ocurrido un error cargando sus credenciales",
    LINIO_INVALID_CREDENTIALS:
      "Credenciales inválidas. Por favor verifique su email, password o ‘API Key’",
    ACTIVATE_FAIL:
      "Oops! Ha ocurrido un error activando la cuenta. Por favor intente más tarde",
    UNEXPECTED_ERROR:
      "Ha ocurrido un error inesperado, por favor contacte al equipo Wivo",
    CANNOT_RESEND_EMAIL:
      "No se ha podido reenviar el correo, por favor intente más tarde",
    ALREADY_ACTIVATE:
      "La cuenta ya ha sido activada por otro usuario de tu empresa",
    CHOOSE_ANOTHER_COMPANY:
      "La empresa ya existe. Escriba otro nombre de empresa",
    FAIL_VERIFICATION:
      "Sus credenciales no pueden ser verificadas, por favor contacte a soporte",
    FAIL_LOGIN_CREDENTIAL: "Email o password incorrectos",
    FAIL_API_KEY: "API KEY incorrecta",
    FAIIL_BOTH: "Credenciales incorrectas",
    TIMEOUT_CREDENTIALS:
      "Oops! Las credenciales no pudieron ser validadas. ¿Deseas guardarlas de todas maneras?",
    REMOVE_SOURCE_ACCOUNT_FAIL:
      "Oops!, ha ocurrido un error al intentar eliminar la cuenta",
    UPDATE_SOURCE_ACCOUNT_FAIL:
      "Oops!, ha ocurrido un error al intentar editar la cuenta",
  },
  ACTIONS: {
    BACK: "Volver",
    DELETE: "Eliminar",
    CREATE_ACCOUNT: "Crear cuenta",
    STOP_FALLOWING: "Dejar de seguir",
  },
  MODAL_BUTTONS: {
    CANCEL: "Cancelar",
    CONFIRM: "Descargar",
    EXPORT: {
      TITLE: "Límite de filas alcanzado",
      MESSAGE:
        "La descarga excede el número máximo de filas (2.000) por lo que se descargará solo la página actual. Avanza a la siguiente página para descargar el resto del reporte.",
    },
    CREATE: "Crear",
    UPDATE: "Modificar",
    CREATE_TITLE: "Nuevo Producto",
    UPDATE_TITLE: "Edición de producto",
  },
  CHIP_FILTER: {
    equals: "es",
    notEquals: "no es",
    set: "no es",
    notSet: "es",
    gt: "mayor que",
    lt: "menor que",
    between: "está entre",
    inDateRange: "está entre",
    contains: "es",
  },
  LOADING_MESSAGES: {
    SENDING: "Enviando información...",
    VERIFIYING: "Verificando...",
    VERIFIYING_INTEGRATIONS: "Verificando interacciones disponibles...",
    VERIFIYING_CREDENTIALS:
      "Validando credenciales. Esto puede tardar un poco...",
    VERIFIYING_CREDENTIALS_SECOND_TRY:
      "Validando credenciales (segundo intento). Esto puede tardar un poco..",
    LOADING_CREDENTIALS: "Cargando credenciales",
    CHANGES_MADE_MESSAGE:
      "Los cambios efectuados en los maestros se verán reflejados en los reportes al día siguiente.",
    REMOVING_ACCOUNT: "Eliminando la cuenta...",
  },
  DASHBOARD: {
    METRICS: {
      sales: "Ventas",
      units: "Unid. vendidas",
      orders: "Órdenes",
      sellerPayment: "Rentabilidad Marketplace",
      renTotal: "Rentabilidad Total",
      avgRentTotal: "% Rentabilidad Total",
      avgSellerPayment: "% Rentabilidad Marketplace",
      price: "Precio",
      discount: "Descuento",
      publishingStateMeasure: "Estado de la publicación",
      stockStateMeasure: "Estado de stock",
      position: "Orden de aparición",
      priceRank: "Ranking de precio",
      publishing: "Publicaciones",
    },
    COMPARE_TO: {
      PM: "Mes pasado",
      PY: "Año pasado",
      CUSTOM: "Personalizado",
      SETTING: "Editar",
      TITLE: "Métodos de comparación",
      DESCRIPTION:
        "Selecciona  el método de comparación que deseas utilizar. Aplicará a toda la cuenta",
      MODES: {
        STANDARD: "Calendario estándar",
        STANDARD_DESCRIPTION:
          "Se compara con el mismo día del mes del periodo comparado. Por ejemplo, si el 15 abril - 21 abril se compara con Mes Anterior, entonces corresponde al periodo 15 marzo - 21 marzo.",
        RETAIL: "Calendario retail",
        RETAIL_DESCRIPTION:
          "Se compara con el mismo día de la semana del periodo comparado. Por ejemplo, si el 15 abril (lunes) - 21 abril (domingo) se compara con Mes Anterior, entonces corresponde al periodo 18 marzo (lunes) - 24 marzo (domingo).",
        PAST: "Período pasado",
        PAST_DESCRIPTION:
          "Se compara con los días pasados del periodo actual. Por ejemplo, si el periodo actual es el 15 abril - 21 abril, entonces El periodo comparado es 8 abril - 14 abril, la semana anterior.",
      },
    },
    GRANULARITY: {
      day: "Día",
      week: "Semana",
      month: "Mes",
    },
    PUBLISHING: {
      ONBOARDING: {
        STAGE1: `Esta herramienta te permitirá monitorear las publicaciones que sean importantes para mejorar su rendimiento`,
        STAGE2: `Las publicaciones seguidas serán monitoreadas a diario para registrar datos importantes en la gestión comercial`,
        STAGE3: `Podrás analizar estos datos de la publicación y mucho más`,
      },
      DELETE_KEYWORD_TITLE: "Eliminar suscripción",
      DELETE_KEYWORD_MESSAGE: `¿Está seguro que desea eliminar la $keywordType $keyword?`,
      DELETE_KEYWORD_MESSAGE_2: `No podrás recuperar la información recopilada.`,
      DELETE_PUBLSHING_MESSAGE: `Está seguro que desea eliminar la suscripción a la publicación $publishing?`,
      NO_INTEGRATION_DATA: `Para agregar publicaciones debes hacerlo desde la herramienta &nbsp; <em>Competencia<em/>.`,
    },
    METRIC_STRING: {
      sales: "Ventas",
      units: "Unidades",
      orders: "Órdenes",
      sellerPayment: "Rentabilidad",
    },
    NO_INTEGRATION_DATA:
      "Para ver tus datos en el dashboard debe integrar al menos un marketplace",
  },
  ONBOARDING: {
    WELCOME: "¡Bienvenido a Wivo Analytics!",
    WELCOME_SUBTITLE:
      "Wivo Analytics te permite crear dashboards y reportes para todo tu equipo que les facilitarán tomar mejores decisiones en la gestión de sus marketplaces.",
    FINISH: "¡Felicidades, ya está todo listo!",
    FINISH_SUBTITLE:
      "En unos minutos recibirás un correo cuando los datos de $integrated_source se encuentren cargados en la aplicación.",
    JOIN_TEAM: "¿Ya tienes un equipo y quieres unirte?",
    JOIN_REQUEST:
      "Por favor contacta a cualquier persona de tu equipo que tenga un usuario en Wivo para que te invite desde la aplicación.",
    GO_TO_DEMO:
      "Si prefieres no conectar tus marketplaces puedes conocer el DEMO de la aplicación con datos ficticios en vez de tus datos.",
    NOT_CONNECT: "¿Prefieres no conectarte por ahora?",
  },
  KEYWORDS: {
    DESCRIPTION: `En este listado se muestran todas las categorías o búsquedas que tengas guardadas. <br/> 
    Las métricas están calculadas con las primeras 5 páginas de resultados.`,
    NEW_KEYWORD: `Agregar`,
    STEP_1: "¿Quieres agregar una búsqueda o categoría?",
    STEP_2: "¿En cuál marketplace?",
    STEP_3_CATEGORY: "Pega el URL de la categoría que quieres agregar",
    STEP_3_KEYWORDS: "Escribe la búsqueda",
    NO_INTEGRATION_DATA:
      "Para identificar tus publicaciones necesitas integrar tu cuenta de Falabella y/o Mercado Libre.",
  },
};
